import { Protocol } from './index';
import { Category } from '../constants';

const defi: Protocol[] = [
  {
    name: '0x',
    url: 'https://0x.org/',
    logoPath: '/logos/0x.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/0xproject',
  },
  {
    name: 'Aave',
    url: 'https://app.aave.com/',
    logoPath: '/logos/aave.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/aave',
  },
  {
    name: 'Alien Base',
    url: 'https://app.alienbase.xyz/',
    logoPath: '/logos/alien_base.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/alienbasedex',
  },
  {
    name: 'Astaria',
    url: 'https://www.astaria.xyz/',
    logoPath: '/logos/astaria.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/AstariaXYZ',
  },
  {
    name: 'Balancer',
    url: 'https://balancer.fi/',
    logoPath: '/logos/balancer.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/Balancer',
  },
  {
    name: 'Baseswap',
    url: 'https://baseswap.fi/',
    logoPath: '/logos/baseswap.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/BaseSwapDex',
  },
  {
    name: 'Beefy',
    url: 'https://beefy.com/',
    logoPath: '/logos/beefy.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/beefyfinance',
  },
    {
    name: 'Clipper',
    url: 'https://clipper.exchange/',
    logoPath: '/logos/clipper.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/Clipper_DEX',
  },
  {
    name: 'Compound',
    url: 'https://compound.finance/',
    logoPath: '/logos/compound.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/compoundfinance',
  },
  {
    name: 'Contango',
    url: 'https://contango.xyz/',
    logoPath: '/logos/contango.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/Contango_xyz',
  },
  {
    name: 'Cred Protocol',
    url: 'https://credprotocol.com/',
    logoPath: '/logos/cred_protocol.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/cred_protocol',
  },
  {
    name: 'Equalizer',
    url: 'https://base.equalizer.exchange/',
    logoPath: '/logos/equalizer.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/Equalizer0x',
  },
  {
    name: 'Extra Finance',
    url: 'https://app.extrafi.io/',
    logoPath: '/logos/extra_finance.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/extrafi_io',
  },
  {
    name: 'Fxdx',
    url: 'https://fxdx.exchange/',
    logoPath: '/logos/fxdx.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/fxdxdex',
  },
  {
    name: 'Intentx',
    url: 'https://intentx.io/',
    logoPath: '/logos/intentx.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/IntentX_',
  },
  {
    name: 'Kyberswap',
    url: 'https://kyberswap.com/',
    logoPath: '/logos/kyberswap.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/KyberNetwork',
  },
  {
    name: 'Moonwell',
    url: 'https://moonwell.fi/',
    logoPath: '/logos/moonwell.png',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/MoonwellDeFi',
  },
  {
    name: 'Odos',
    url: 'https://odos.xyz/',
    logoPath: '/logos/odos.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/odosprotocol',
  },
  {
    name: 'Pancakeswap',
    url: 'https://pancakeswap.finance/',
    logoPath: '/logos/pancakeswap.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/pancakeswap',
  },
  {
    name: 'Sushiswap',
    url: 'https://www.sushi.com/',
    logoPath: '/logos/sushiswap.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/sushiswap',
  },
  {
    name: 'Uniswap',
    url: 'https://uniswap.org/',
    logoPath: '/logos/uniswap.jpg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/Uniswap',
  },
  {
    name: 'sudoswap',
    url: 'https://sudoswap.xyz/',
    logoPath: '/logos/sudoswap.jpeg',
    category: Category.Defi,
    twitterUrl: 'https://twitter.com/sudoswap',
  },
];

export { defi };
